import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { loadAccount } from "./actions/authActions";

import { baselightTheme } from "./shared/theme/DefaultColors";
import { SnackbarProvider } from "notistack";
import {
  AuthProvider,
  PrivateRoute,
  PublicRoute
} from "./shared/components/container/SecurityContainer";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import FullLayout from "./shared/layouts/full/FullLayout";
import BlankLayout from "./shared/layouts/blank/BlankLayout";
import Error from "./pages/error/Error";
import ApplyNewLoan from "./pages/apply-new-loan/ApplyNewLoan";
import Payment from "./pages/payment/Payment";
import PaymentComplete from "./pages/payment-complete/PaymentComplete";
import PaymentTermsAndConditions
  from "./pages/payment-terms/PaymentTermsAndConditions";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";
import { analyticsTracker } from "./services/analytics";
import ForgotEmail from "./pages/forgot-email/ForgotEmail";

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userStatus: state.auth.account?.status
});

const App = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_MOCK !== "1") {
      analyticsTracker.start();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadAccount());
    }
  }, [dispatch, isAuthenticated]);
  return (
    <AuthProvider isAuthenticated={isAuthenticated}>
      <ThemeProvider theme={baselightTheme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "top"
                          }}
                          autoHideDuration={3000}>
          <Routes>
            <Route path='/' element={<FullLayout />}>
              <Route index path=''
                     element={<Navigate to='/dashboard' replace />} />
              <Route path='dashboard'
                     element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path='pay/:type'
                     element={<PrivateRoute><Payment /></PrivateRoute>} />
              <Route path='apply-new-loan'
                     element={<PrivateRoute><ApplyNewLoan /></PrivateRoute>} />
              <Route path='payment-terms'
                     element={
                       <PrivateRoute><PaymentTermsAndConditions /></PrivateRoute>} />
              <Route path='terms'
                     element={
                       <PrivateRoute><Terms /></PrivateRoute>} />
              <Route path='privacy'
                     element={
                       <PrivateRoute><Privacy /></PrivateRoute>} />
              <Route path='*' element={<Navigate to='/error/404' replace />} />
            </Route>
            <Route path='/auth' element={<BlankLayout />}>
              <Route path='login'
                     element={<PublicRoute><Login /></PublicRoute>} />
              <Route path='forgot-email'
                     element={<PublicRoute><ForgotEmail /></PublicRoute>} />
            </Route>
            <Route path='/pay' element={<BlankLayout />}>
              <Route path='complete' element={
                <PublicRoute><PaymentComplete /></PublicRoute>} />
            </Route>
            <Route path='/error/404' element={<Error />} />
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default connect(mapStateToProps)(App);
